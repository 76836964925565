<template>
    <grid-view 
        title="Работы"
        filter
        create
        create-title="Создать работу"
        url-create="/job/create"
        url-update="/job/update/:id"
        store-module="job"
        :headers="headers"
        :format="formatItems"
        :data-table-options="{
            'show-select': true,
        }"
    >
        <!-- Filter -->
        <template v-slot:filter="{ filter }">
            <v-progress-circular
                v-if="loading.initial"
                :size="50"
                :color="$const.color.primary"
                indeterminate
                class="d-flex mx-auto"
            ></v-progress-circular>
            <v-row v-else>
                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.year"
                        label="Календарный год" 
                        type="number" 
                        placeholder="1"
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-select
                        v-model="filter.subject"
                        required
                        :items="subjects"
                        label="Предмет"
                    />
                </v-col>

                <v-col lg="6" sm="12">
                    <v-select
                        v-model="filter.grade"
                        :disabled="!filter.subject"
                        required
                        :items="getGrades(filter.subject)"
                        label="Класс"
                    />
                </v-col>
                

                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.option"
                        label="Вариант" 
                        type="number" 
                        placeholder="1"
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>


                <v-col lg="6" sm="12">
                    <v-select
                        v-model="filter.category"
                        required
                        :items="categories"
                        label="Категория"
                    />
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.collection"
                        label="Коллекция" 
                        type="number" 
                        placeholder="1"
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
        <!-- Extra buttons in title -->
        <template #[`title.right`]>

            <v-btn to="/job/answers-pdf" :color="$const.color.primary" class="ml-auto mr-2 white--text">
                <v-icon left dark>
                    mdi-file-pdf-box
                </v-icon>
                <span>Ответы в pdf</span>
            </v-btn>

        </template>
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'

export default {
    name: 'JobIndex',
    components: { GridView }, 
    data () {
        return {
            loading: {
                initial: false
            }
        }
    },
    computed: {
        headers () {
            return [
                { text: 'ID', value: 'id', width: 1 },
                { text: 'Активность', value: 'active', width: 1, sortable: false },
                { text: 'Название', value: 'name', width: 1, sortable: false },
                { text: 'Предмет', value: 'subject', width: 1 },
                { text: 'Класс', value: 'grade', width: 1 },
                { text: 'Вариант', value: 'option', width: 1 },
                { text: 'Категория', value: 'category', width: 1 },
                { text: 'Коллекция', value: 'collection', width: 1 },
                { text: '', value: 'edit', width: 1, sortable: false },
                { text: '', value: 'remove', width: 1, sortable: false }
            ]
        },
        categories () {
            return [ {value: 'test', text: 'ВПР'}, {value: 'control', text: 'Контрольная работа'} ]
        },
        subjects () {
            return this.$store.state.app.subjects
        }
    },
    async created () {
        this.loading.initial = true
        try {
            await this.$store.dispatch('app/waitUntilRequiredDataLoaded')
        } catch (e) {
            console.error(e)
        } finally {
            this.loading.initial = false
        }
    },
    methods: {
        getGrades (subject) {
            if (!subject) { return [] }
            return this.subjects.find(item => item.value === subject)?.grades.flat()
        },
        formatItems(items) {
            return _.map(items, (item) => {
                // Active
                _.set(item, 'active', item.active === true ? 'Да' : 'Нет');
                // Category
                _.set(item, 'category', item.category === 'test' ? 'ВПР' : 'Контрольная работа');
                // Role
                const role_i = _.findIndex(this.$store.state.job.roles, {value: item.role});
                _.set(item, 'role', _.get(this, `$store.state.job.roles.${role_i}.text`, 'Неизвестно'));

                return item;
            })
        } 
    }
}
</script>